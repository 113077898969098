@import '../../assets/css/variables';

.btn {
    display: inline-flex !important;
    height: 30px;
}

.btnCreate, .btnWatermark {
    background-color: #242222 !important;
    height: 48px;
    width: max-content !important;

    div {
        color: #FFFFFF
    }
}

.btnWatermark {
    margin-right: auto;
}

.searchContainer {
    display: flex;
    justify-content: end;
    margin-bottom: 16px;
    gap: 8px;

    @media screen and (max-width: $small-screen) {
        flex-direction: column;
    }
}

.rowSelector {
    width: 110px !important;
    height: 44px !important;
}

.rowSelectorSearch {
    width: 110px !important;
    height: 44px !important;
}