@import '../../assets/css/variables.scss';

.form {
    background-color: #FFFFFF;
    border-radius: 6px;
    margin: 0 !important;
    width: 184px;
}

.select {
    height: 48px;
    padding: 9px 13px 9px 13px;
    border-radius: 6px;
    gap: 4px;
    div {
        display: flex;
        padding: 0 4px 0 0 !important;
    }
}

.label {
    font-family: $font-family-lato !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    letter-spacing: 0 !important;
    top: 2px !important;
    color: #808080 !important;
}