@import '../../../assets/css/variables.scss';

.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: black;
  color: white;
  height: 72px;
  padding: 0 40px;
}

.userInfo {
  display: flex;
  cursor: pointer;
  align-items: center;
}

.username {
  margin-left: 7px;
  @media screen and (max-width: $small-screen ) {
    display: none;
  }
}

.span {
  font-size: 14px !important;
  color: rgba(128, 128, 128, 1) !important;
}


.navContainer {
  @media screen and (max-width: $medium-screen ) {
    padding: 0 10px;
  }
}


.avatar {
  width: 28px;
  height: 28px;
}

.avatarImage {
  width: 28px;
  height: 28px;
  border-radius: 28px;
  background-color: #ffffff;
}

.settingWrapperSignIn {
  flex-direction: column !important;
  gap: 2px !important;
  border-top: none !important;
}

.settingWrapper {
  display: flex;
  gap: 8px;
  border-top: 1px solid #F3F4F6;
  width: 200px;
  cursor: pointer;
}

.text {
  font-family: $font-family-lato;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #242222;
}

.subtext {
  font-family: $font-family-lato;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #808080;
}

.iconWrapper {
  display: flex;
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
}

.selector {
  width: fit-content !important;
}