.pageBox {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.mainBox {
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
}