.navContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    height: 70px;
    gap: 10px;
}

.navLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.link {
    text-decoration: none;
    color: #1A1523;
}

.activeLink {
    text-decoration: none;
    color: #1A1523;
    border-bottom: 4px solid red;
    padding: 16px 0
}