@import '../../assets/css/variables.scss';

.container {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    z-index: 1001;
    background-color: #00000040;
}

.modal {
    display: flex;
    flex-direction: column;
    position: absolute;
    max-width: 512px;
    border-radius: 12px;
    gap: 32px;
    box-shadow: 0 10px 10px -5px #0000000A;
    background-color: #FFFFFF;
    margin: 16px;
    box-sizing: border-box;
    @media screen and (max-width: $small-screen) {
        padding: 20px 32px 20px 32px;
    }
    @media screen and (min-width: $small-screen) {
        padding: 40px 64px 40px 64px;
    }
}

.textWrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    text-align: center;
}

.text {
    font-family: $font-family-lato;
    letter-spacing: 0;
    text-align: center;
}

.title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
}

.subtext {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
}

.btnWrapper {
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 16px;
}

.cancelBtn {
    display: flex;
    padding: 12px 24px 12px 24px;
    border-radius: 6px;
    gap: 8px;
    background-color: #FFFFFF;
    border: 1px solid #242222;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: fit-content;
    align-self: center;
}

.confirmBtn {
    display: flex;
    padding: 12px 24px 12px 24px;
    border-radius: 6px;
    gap: 8px;
    background-color: #DC2626;
    box-shadow: 0 1px 2px 0 #0000000D;
    cursor: pointer;
    color: #FFFFFF;
    justify-content: center;
    align-items: center;
    width: fit-content;
    align-self: center;
}