.header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    gap: 24px;
}

.headerTxt {
    font-size: 18px;
    font-weight: 500;
    color: #1F1F1F;
}

.icon {
    cursor: pointer;
}

.btn {
    background-color: #242222 !important;
    height: 48px;
    width: max-content !important;
    padding: 12px 24px 12px 24px;
    div {
        color: #FFFFFF
    }
}

.btnContainer {
    display: flex;
    gap: 16px;
}