
@keyframes fadeInScale {
    0% {
        opacity: 0;
        transform: translate(-50%, -50%) scale(0.8);
    }
    100% {
        opacity: 1;
        transform: translate(-50%, -50%) scale(1);
    }
}

.alert {
    position: fixed;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 300px;
    width: 100%;
    justify-content: center;
    align-items: center;
    z-index: 9001;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px !important;
    animation: fadeInScale 0.3s ease-out;
}