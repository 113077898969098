$font-path: '../css/fonts';
$font-family-lato: 'Lato';


$x-small-screen: 500px;
$small-screen: 576px;
$medium-screen: 768px;
$large-screen: 992px;
$xl-large-screen: 1200px;
$xxl-large-screen: 1400px;

@font-face {
    font-family: 'Lato';
    src: url('#{$font-path}/Lato-Regular.ttf') format('truetype');
}